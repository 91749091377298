// needed for sanity image utils
import clientConfig from '@client-config'
import imageUrlBuilder from '@sanity/image-url'

/**
 * Cleanest way to html entities decode
 * @param input
 * @returns {string}
 */
export function htmlDecode(input) {
  if (typeof window !== 'undefined') {
    const doc = new DOMParser().parseFromString(input, 'text/html')
    return doc.documentElement.textContent
  }
  return input
}

/**
 * Truncate strings
 * @param str = string to truncate
 * @param n = number of characters to truncate to
 * @param symbol = symbol to use when truncated — default is "..."
 * @returns {string}
 */
export const truncate = (str, n, symbol = ' ...') => {
  return str.length > n ? str.substr(0, n - 1) + ` ${symbol}` : str
}

// build out image object for sanity
export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

// Build image URL for sanity images
const builder = imageUrlBuilder(clientConfig.sanity)

export function imageUrlFor(source) {
  return builder.image(source)
}

// Format date—changing this will change all dates show in site
export function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return new Date(dateString).toLocaleDateString(undefined, options)
}

export async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
  return response.json() // parses JSON response into native JavaScript objects
}
