import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { useAppState } from '../Layout'
import { Link, SlideDown } from '@elements'

export const AccordionMenu = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityMenu(filter: { title: { eq: "Main Navigation" } }) {
        edges {
          node {
            items {
              text
              pageRoute {
                slug {
                  current
                }
              }
              _key
            }
            title
          }
        }
      }
    }
  `)

  const mainMenu = data.allSanityMenu.edges[0].node.items
  const { isMenuOpen } = useAppState()

  return (
    <SlideDown
      isActive={isMenuOpen}
      distance={20}
      direction={-1}
      className="absolute w-full z-50 text-white bg-brand-gray-500"
    >
      <nav className="px-2 pt-2 pb-3 text-center divide-y divide-brand-gray-300 w-full flex flex-col justify-center items-center">
        {mainMenu.map((item, idx) => (
          <Link
            key={item._key}
            to={`/${item.pageRoute?.slug?.current}`}
            className="w-full block py-5 text-base text-white hover:bg-brand-gray-400 uppercase font-semibold tracking-widest"
          >
            {item.text}
          </Link>
        ))}
      </nav>
    </SlideDown>
  )
}
