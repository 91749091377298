// require('./src/styles/base.css')

import React from 'react'
import './src/styles/base.css'
import { PageWrap } from '@globals'

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  //   console.log(props)

  return <PageWrap {...props}>{element}</PageWrap>
}
