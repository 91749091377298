import { useState, useEffect } from 'react'

export const useGeoLocation = () => {
  const [location, setLocation] = useState({ lat: null, lng: null })

  const [error, setError] = useState()

  useEffect(() => {
    if (!navigator.geolocation) {
      setError('Geolocation is not supported.')
      return
    }
    const handleError = (err) => {
      setError(err.message)
    }
    const handleSuccess = (position) => {
      const lat = position.coords.latitude
      const lng = position.coords.longitude

      setLocation({ lat, lng })
    }
    navigator.geolocation.getCurrentPosition(handleSuccess, handleError)
  }, [])

  return [location, error]
}
