import * as React from 'react'

export const useForm = (initial = {}) => {
  const [inputs, setInputs] = React.useState(initial)
  const initialValues = Object.values(initial).join('')

  React.useEffect(() => {
    setInputs(initial)
  }, [initialValues])

  function handleChange(e) {
    let { value, name, type } = e.target
    if (type === 'number') {
      value = parseInt(value)
    }
    if (type === 'file') {
      ;[value] = e.target.files
    }
    setInputs({
      ...inputs,
      [name]: value,
    })
  }

  function reset() {
    setInputs(initial)
  }

  function clear() {
    const blankState = Object.fromEntries(
      Object.entries(inputs).map(([key, value]) => [key, ''])
    )

    setInputs(blankState)
  }

  return { inputs, handleChange, reset, clear }
}
