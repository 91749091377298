import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

export const SlideDown = ({
  isActive,
  children,
  direction = 0,
  distance = 200,
  className,
}) => {
  return (
    <AnimatePresence>
      {isActive && (
        <motion.div
          className={className}
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
