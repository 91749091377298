import React from 'react'
import { useAppState } from '../Layout'
import { motion } from 'framer-motion'
export const DesktopNavigation = () => {
  const { isMenuOpen, toggleMenu } = useAppState()

  return (
    <nav className="flex items-center lg:hidden">
      <button onClick={toggleMenu} className="w-8 h-8 focus:outline-none ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#fff"
        >
          {isMenuOpen && (
            <motion.path
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M 2 2 L 22 22 M 2 22 L22 2"
            ></motion.path>
          )}
          {!isMenuOpen && (
            <motion.path
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M 4 2 L 24 2 M 4 10 L 24 10 M 4 18 L 24 18"
            ></motion.path>
          )}
        </svg>
      </button>
    </nav>
  )
}
