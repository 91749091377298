import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

export const SEO = ({
  description,
  lang,
  meta,
  title,
  image,
  disallowRobots = false,
}) => {
  const { allSanitySeoSettings } = useStaticQuery(
    graphql`
      query {
        allSanitySeoSettings {
          edges {
            node {
              openGraph {
                title
                description
                image {
                  asset {
                    fixed(width: 1200) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const openGraph = allSanitySeoSettings.edges[0].node.openGraph
  const metaTitle = title || openGraph?.title
  const metaDescription = description || openGraph?.description
  const metaImage = image || openGraph?.image?.asset?.fixed?.src

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `title`,
          property: `og:title`,
          content: metaTitle,
        },
        {
          name: `description`,
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `image`,
          property: 'og:image',
          content: metaImage,
        },
        {
          name: `type`,
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(meta)
        .concat(
          disallowRobots
            ? [
                {
                  name: `robots`,
                  content: `nofollow, noindex`,
                },
              ]
            : []
        )}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}
