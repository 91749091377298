import React, { useEffect, useState } from 'react'
import { useQueryParam, useSessionStorage, useGeoLocation } from '@hooks'
import includes from 'lodash/includes'

import Modal from 'react-modal'

Modal.setAppElement('#___gatsby')

const UNKNOWN_DEALER_ID = '11111'

function modelNameToCode(name) {
  const mapping = {
    // name: code
    'Bay Star': 'bsca',
    'Bay Star Sport': 'btca',
    'Dutch Star': 'dsdp',
    Essex: 'exdp',
    'King Aire': 'kgdp',
    'London Aire': 'ladp',
    'Mountain Aire': 'madp',
    Ventana: 'vtdp',
    'Ventana LE': 'vldp',
    'Canyon Star': 'csca',
    'New Aire': 'nadp',
    SuperStar: 'sscc',
    'Supreme Aire': 'sacc',
    'Kountry Star': 'ksdp',
  }
  return mapping[name]
}

export const PageWrap = (props) => {
  const [queryDealerId, setQueryDealerId] = useQueryParam(`dealerId`)
  const locationConfirmed = useSessionStorage(`locationConfirmed`, 'false')

  const [dealerInfo, setDealerInfo] = useState({ name: '', city: '' })

  useEffect(() => {
    fetch(`/api/dealer?dealerId=${queryDealerId}`)
      .then((res) => res.json())
      .then((data) => {
        data = JSON.parse(data)
        setDealerInfo({ name: data.dealer.name, city: data.dealer.city })

        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['trackCustomBehavioralEvent', {
          name: "newmar_dealer_id",
          properties: {
            newmar_id: data.dealer.newmar_id
          }
        }]);

      })
  }, [queryDealerId])

  const modelName = props.data?.sanityModel?.name
  const modelCode = modelNameToCode(modelName)

  //REMOVE THIS AFTER TESTING

  //REMOVE THIS AFTER TESTING

  return (
    <div>
      <ConfirmationModal
        dealer={dealerInfo}
        modelCode={modelCode}
        modalIsOpen={
          modelName &&
          dealerInfo.name &&
          queryDealerId &&
          queryDealerId !== UNKNOWN_DEALER_ID &&
          locationConfirmed.value === 'false'
        }
        setLocationConfirmed={locationConfirmed.setValue}
      />

      <div>{props.children}</div>
    </div>
  )
}

function ConfirmationModal({
  dealer,
  modelCode,
  modalIsOpen,
  setLocationConfirmed,
}) {
  const [locationCorrect, setLocationCorrect] = React.useState(true)

  useEffect(() => {
    modalIsOpen && (document.body.style.overflow = 'hidden')
    !modalIsOpen && (document.body.style.overflow = 'unset')
  }, [modalIsOpen])

  return (
    <div className="w-full">
      <Modal
        isOpen={!!modalIsOpen}
        className="Modal__Content flex flex-col items-center justify-center h-screen"
        overlayClassName="Modal__Overlay"
      >
        <div className="flex">
          <div className="bg-white p-5">
            {locationCorrect && (
              <div>
                <h2 className="mb-2 text-center text-lg font-bold text-brand-gray-500 ">
                  Welcome!
                </h2>
                <p>
                  It looks like you're at {dealer.name} in{' '}
                  {dealer.city}. Is that right?
                </p>
                <div className="flex mb-5 mt-5">
                  <div className="w-1/2 mr-2">
                    <button
                      id="relocalization-yes"
                      className=" inline-block inline-block w-full flex items-center justify-center px-4 md:px-7 py-3 border-3 border-brand-red-200 text-sm md:text-base font-bold uppercase tracking-wide text-brand-gray-500 hover:text-white hover:bg-brand-red-200 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red-200 transition duration-150 transition duration-150"
                      onClick={() => {
                        setLocationConfirmed(true)
                      }}
                    >
                      Yes
                    </button>
                  </div>

                  <div className="w-1/2 ml-2">
                    <button
                      id="relocalization-no"
                      className=" inline-block inline-block w-full flex items-center justify-center px-4 md:px-7 py-3 border-3 border-brand-red-200 text-sm md:text-base font-bold uppercase tracking-wide text-brand-gray-500 hover:text-white hover:bg-brand-red-200 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red-200 transition duration-150 transition duration-150"
                      onClick={() => {
                        setLocationCorrect(false)
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div>
              {!locationCorrect && <LocationSelector modelCode={modelCode} />}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

function LocationSelector({ modelCode }) {
  const [coords, geoError] = useGeoLocation()
  const [nearestDealers, setNearestDealers] = useState([])
  const [updatedDealerId, setUpdatedDealerId] = useState(UNKNOWN_DEALER_ID)
  const [queryDealerId, setQueryDealerId] = useQueryParam(`dealerId`)
  const locationConfirmed = useSessionStorage(`locationConfirmed`, 'false')
  const [status, setStatus] = useState('loading')

  useEffect(() => {
    if (geoError) {
      locationConfirmed.setValue(true)
      setQueryDealerId(UNKNOWN_DEALER_ID)
      window.location.reload()
    }

    if (coords.lat && coords.lng) {
      console.log(coords)
      getZipCode(coords.lat, coords.lng)
        .then((zip) => {
          getNearestDealersByZipModel(zip, modelCode).then((data) => {
            setNearestDealers(data)
            setStatus('loaded')
          })
        })
        .catch((e) => {
          // something went wrong with an api
          locationConfirmed.setValue(true)
          setQueryDealerId(queryDealerId || UNKNOWN_DEALER_ID)
          window.location.reload()
        })
    }
  }, [coords, modelCode])

  useEffect(() => {
    let timer
    if (status === 'complete') {
      timer = setTimeout(() => {
        setQueryDealerId(updatedDealerId || UNKNOWN_DEALER_ID)
        locationConfirmed.setValue(true)
        window.location.reload()
      }, 3000)
    }

    return () => {
      timer && clearTimeout(timer)
    }
  }, [status])

  switch (status) {
    case 'complete':
      return (
        <h3 className="text-lg font-bold text-brand-gray-500 mb-5">
          Thank you for your feedback.
        </h3>
      )
    case 'loaded':
      return (
        <div>
          <h3 className="text-lg text-center font-bold text-brand-gray-500">
            Are you at one of these Newmar dealers?
          </h3>
          <div className="">
            {nearestDealers.map((dealer, idx) => {
              return (
                <button
                  className="text-left w-full mb-3 shadow px-4 py-3 pb-2.5"
                  onClick={() => {
                    setStatus('complete')
                    setUpdatedDealerId(dealer.newmar_id)
                  }}
                  key={dealer.id}
                >
                  {dealer.name} ({dealer.city}, {dealer.state})
                </button>
              )
            })}
            <button
              className="text-left w-full mb-3 shadow px-2 py-3"
              onClick={() => {
                setStatus('complete')
              }}
            >
              None of the above
            </button>
          </div>
        </div>
      )
  }

  return <div>Fetching nearest dealers list...</div>
}

function getZipCode(lat, lng) {
  const endpoint = 'https://maps.googleapis.com/maps/api/geocode/json'

  return fetch(
    `${endpoint}?latlng=${lat},${lng}&location_type=ROOFTOP&key=${process.env.GATSBY_GOOGLE_API_KEY}`
  )
    .then((res) => res.json())
    .then((data) => {
      // parse results
      console.log(data)
      return data.results
        .flatMap((r) => {
          return r.address_components.map((c) => {
            return includes(c.types, 'postal_code') ? c.long_name : null
          })
        })
        .reduce((col, cur) => {
          return cur ? cur : col
        }, false)
    })
}

function getNearestDealersByZipModel(zip, model) {
  const endpoint = 'https://newmar.e3portal.info/api/lead/assignment'
  return fetch(`/api/getNearestDealer?zip=${zip}&model=${model}`)
    .then((res) => res.json())
    .then((data) => {
      return data
    })
}
