// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-qr-localization-3-k-fdj-a-cinw-xi-7-q-td-jsx": () => import("./../../../src/pages/qr-localization/3KFdjACinwXi7QTd.jsx" /* webpackChunkName: "component---src-pages-qr-localization-3-k-fdj-a-cinw-xi-7-q-td-jsx" */),
  "component---src-templates-model-feature-jsx": () => import("./../../../src/templates/modelFeature.jsx" /* webpackChunkName: "component---src-templates-model-feature-jsx" */),
  "component---src-templates-model-jsx": () => import("./../../../src/templates/model.jsx" /* webpackChunkName: "component---src-templates-model-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

