import React from 'react'
import { Link } from 'gatsby'
import { DesktopNavigation } from './Navigation'
import Logo from '../../images/png/icon.png'
export const Header = () => {
  return (
    <header className="relative max-w-7xl bg-brand-gray-500 py-6 mx-auto flex items-center justify-between px-4 sm:px-6 overflow-hidden">
      <div className="flex items-center flex-1">
        <div className="flex items-center justify-between w-full">
          <Link to="/" className="">
            <img
              src={Logo}
              className="h-8 w-auto sm:h-10 logo"
              alt="Newmar Logo"
            />
          </Link>
          <DesktopNavigation />
        </div>
      </div>
    </header>
  )
}
