import React, { createContext, useContext } from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { useToggle } from '@hooks'
import { AccordionMenu } from './Navigation'

export const AppContext = createContext({
  isMenuOpen: false,
})

export const Layout = ({ children }) => {
  const { isToggled, toggle } = useToggle(false)
  return (
    <AppContext.Provider value={{ isMenuOpen: isToggled, toggleMenu: toggle }}>
      <div className="bg-brand-gray-100">
        <div className="font-sans antialiased  bg-white relative">
          <Header />
          <AccordionMenu />
          <main className="flex flex-col gap-y-14 max-w-7xl mx-auto px-4 sm:px-6 py-14">
            {children}
          </main>
          <Footer />
        </div>
      </div>
    </AppContext.Provider>
  )
}

export const useAppState = () => useContext(AppContext)
